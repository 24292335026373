body {
    background-color: white !important;
}
.wallet-page {
    margin-top: 60px;
    position: relative;
    background-color: white;
    
}

.insurerListContainer{
    box-shadow: 0px 1px 4px 1px rgb(0 0 0 / 25%);
	border-radius: 8px;
    margin: 16px 16px 60px;
    flex-grow: 6;
}

.insurerList{
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: center;
    border-top: 1px solid #E0E0E0;
    padding: 11px;
}
.insurerList img{
    margin: auto;
}
.insurerList div{
    display: flex;
    width: 25%;
    margin-bottom: 15px;
}

.insurerListFooter{
    display: flex;
    justify-content: center;
    align-items: baseline;
    text-decoration-line: underline;
    color: #00A465;
    border-top: 1px solid #E0E0E0;
    padding: 8px;
}

.insurerListFooter img{
    padding-left: 4px;
}
.insurerListFooter div{
    cursor: pointer;
}


@media screen and (max-width: 767px) {
    .insurerListContainer{
        margin:10px 16px;
        justify-content: space-between;
    }

    .insurerList{
        flex-wrap: wrap;
        justify-content: start;
        padding: 12px 26px 12px 16px;
    }

    .insurerList img{
        margin: auto;
    }
    .insurerList div{
        display: flex;
        width: 33%;
    }
}