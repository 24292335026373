.maintenance-desktop{	
    display: flex;	
    flex-direction: row;	
}	

.maintenance-mobile .mobile-maintenance-img{	
    display: flex;	
    flex-direction: column;	
    width: "100%";	
    height: "100%"	
}	

.description{	
    font-size: 18px;	
    font-weight: bold;	
    width: 350px; 	
    color: "#2B2B2B"	

}	
.consolation-note{	
    color: black	
}	
span{	
    font-weight: "bold";	
    color: "#2B2B2B",	

}	

.abc{	
    color: "#494949";	
    font-weight: "bold"	
}