/* For demo */
.ant-carousel .slick-slide {
    text-align: center;
    overflow: hidden;
    margin-top: 0.5rem;
  }
  
  .ant-carousel .slick-slide h3 {
    color: rgb(13, 14, 12);
  }
  
  .home .ant-card-meta {
    display: flex;
    flex-direction: column;
  }
  
  .home .ant-card-body {
    padding: 15px;
    width: 100%;
  }
  
  .home .ant-card-meta-description {
    font-size: 0.9rem;
  }
  
  .home .ant-card-head {
    background-color: rgb(176, 224, 206);
    background-image: linear-gradient(rgb(134, 206, 179), rgb(242, 248, 246));
  }
  .home {
    box-shadow: 0 0 5px rgb(118, 139, 131);
  }
  .home .ant-card-head-title {
    padding: 10px;
  }
  
  .testimonial-background {
    background-color: #ccdcdf;
    border: 1px solid rgb(241, 243, 241);
    border-radius: 0.5rem;
    padding: 0.5rem;
    ;
  }
  