/***************************   FONTS  *************************************/

@font-face {
    font-family: 'robotolight';
    src: url('fonts/roboto-light-webfont.eot');
    src: url('fonts/roboto-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/roboto-light-webfont.woff2') format('woff2'),
         url('fonts/roboto-light-webfont.woff') format('woff'),
         url('fonts/roboto-light-webfont.ttf') format('truetype'),
         url('fonts/roboto-light-webfont.svg#robotolight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotomedium';
    src: url('fonts/roboto-medium-webfont.eot');
    src: url('fonts/roboto-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/roboto-medium-webfont.woff2') format('woff2'),
         url('fonts/roboto-medium-webfont.woff') format('woff'),
         url('fonts/roboto-medium-webfont.ttf') format('truetype'),
         url('fonts/roboto-medium-webfont.svg#robotomedium') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'robotoregular';
    src: url('fonts/roboto-regular-webfont.eot');
    src: url('fonts/roboto-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/roboto-regular-webfont.woff2') format('woff2'),
         url('fonts/roboto-regular-webfont.woff') format('woff'),
         url('fonts/roboto-regular-webfont.ttf') format('truetype'),
         url('fonts/roboto-regular-webfont.svg#robotoregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/MaterialIcons-Regular.eot'); /* For IE6-8 */
  src: url('fonts/MaterialIcons-Regular.eot?#iefix') format('embedded-opentype'),
        url('fonts/MaterialIcons-Regular.woff2') format('woff2'),
        url('fonts/MaterialIcons-Regular.woff') format('woff'),
        url('fonts/MaterialIcons-Regular.ttf') format('truetype'),
        url('fonts/MaterialIcons-Regular.svg#Material Icons') format('svg');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
* {
  box-sizing: border-box;
}
html,
body,
div,
span,
applet,
object,
iframe,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
li,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  /* vertical-align: baseline; */
  background: transparent;
}
ol,
ul,
li {
  list-style: none;
}
ul,
li {
  margin: 0;
  padding: 0;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
:focus {
  outline: 0;
}
ins {
  text-decoration: none;
}
del {
  text-decoration: line-through;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
body {
  padding: 0;
  margin: 0;
  font-size: 14px;
  line-height: 1.4;
  font-family: "robotoregular", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  height: 100%;
}

/******************************   CUSTOM STYLES  ***********************************************/

h1 { 
    display: block;
    font-size: 2rem;
    margin-top: 0.67rem;
    margin-bottom: 0.67rem;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
}
h2 {
    display: block;
    font-size: 1.5rem;
    margin-top: 0.83rem;
    margin-bottom: 0.83rem;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
}
h3 { 
    display: block;
    font-size: 1.17rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
}
h4 { 
    display: block;
    margin-top: 1.33rem;
    margin-bottom: 1.33rem;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
}
h5 { 
    display: block;
    font-size: .83rem;
    margin-top: 1.67rem;
    margin-bottom: 1.67rem;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
}
h6 { 
    display: block;
    font-size: .67rem;
    margin-top: 2.33rem;
    margin-bottom: 2.33rem;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
}

[disabled]{
  pointer-events: none;
  opacity: 0.8;
 }

.text-left{
    text-align: left;
}
.text-right{
    text-align: right!important;
}
.mt30{
    margin-top: 30px;
}
.img-responsive{
  max-width: 100%;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1210px;
  }
}



/* common share modal */
@media (max-width:767px){
  .tm-modal-document{
   max-width: 100%;
   padding:20px;
   margin: auto;
  }
}
@media (min-width:767px){
  .tm-modal-document{
   max-width: 400px;
   padding:20px;
  }
}

.tm-modal-header div{
  color:#ffffff;
}

.capitalize{
text-transform: capitalize;
}

.uppercase{
  text-transform: uppercase;
}