.mintpay-features {
    display: flex;
    flex-direction: column;
    
}

.mintpay-features .feature-section {
    display: flex;
    flex-direction: row;  
    margin-bottom: 50px;  
}

.mintpay-features .feature-section-bottom {
    display: flex;
    flex-direction: row;  
    margin-bottom: 20px;  
}

.mintpay-features .feature-section-bottom .title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #4D5556;
}

.mintpay-features .feature-section .title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #4D5556;
}

.mintpay-features .feature-section p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #272727;
}

.mintpay-feature-desktop .title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #121617;
    margin-top: 22px;
    margin-bottom: 14px;
}

.mintpay-feature-desktop .feature-box {
    background: #FFFFFF;
    box-shadow: 0px 1px 4px 1px rgb(0 0 0 / 25%);
    border-radius: 8px;
    padding: 10px;
}

.mintpay-feature-Mobile .heading {
    display:block; 
    margin:20px
}

.mintpay-feature-Mobile .heading h2 {
    font-size: 16px;
    text-align:center; 
    color: #121617;
    border-bottom:1px solid #D2D5D6; 
    position:relative;
    font-weight: bold;
}
.mintpay-feature-Mobile .heading h2 span {
    background-color: white; 
    position: relative; 
    top: 10px; 
    padding: 0 10px;
}

.mintpay-feature-Mobile .mintpay-features {
    padding: 15px;
}

.mintpay-feature-Mobile .mintpay-features .feature-description {
    margin-top: 20px;
}

.setup-mintpay {
    bottom: 0;
    position: fixed;
    padding: 16px;
    background: #FFFFFF;
    box-shadow: 0px -1px 16px -5px rgb(0 0 0 / 16%);
    width: 100%;

}